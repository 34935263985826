import {
  usePostLoginMutation,
  usePostLoginNoCredentialsMutation,
  usePostLogoutMutation,
} from 'api/auth/authApi';
import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { RootState } from './redux/store';
import HeaderCsb from './components/HeaderCsb/HeaderCsb';
import SpineMenuCsb from './components/SpineMenuCsb/SpineMenuCsb';
import { LicenseManager } from 'ag-grid-enterprise';
import Config from './config.json';
import {
  consoleErrorMessage,
  currentClearTimeout,
  currentSetTimeout,
} from 'utils/commonFunctions/CommonFunctions';
import { useServerSideEvents } from 'utils/hooks/useSSE';
import RunOrchestrationsLazy from 'pages/RunOrchestration/RunOrchestrations.lazy';
import OrchestrationDetailsLazy from 'pages/OrchestrationDetails/OrchestrationDetails.lazy';
import DashboardLazy from 'pages/Dashboard/Dashboard.lazy';
import ManageModulesLazy from 'pages/ManageModules/ManageModules.lazy';
import { WkIconDefaultSprite } from '@wk/components-v3-react16';
import NotificationBox from 'components/NotificationBox/NotificationBox';
import {
  HTTP_STATUS_CODES,
  NOTIFICATION_BOX_CONTENT,
  NOTIFICATION_BOX_MESSAGES,
} from 'utils/common-constants';
import { setAuthError } from 'redux/auth/AuthSlice';

const EVENTS_FOR_LOGOUT = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

const DEFAULT_TIMEOUT = 60 * 15 * 1000;

function App(): JSX.Element {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const authErrorCode = useAppSelector(
    (state: RootState) => state.auth.authErrorCode
  );
  const showAuthErrorNotification = [
    HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR,
    HTTP_STATUS_CODES.UNAUTHORIZED,
  ].includes(authErrorCode as HTTP_STATUS_CODES);
  const logoutTimer: any = useRef(null);
  const [login] = usePostLoginMutation();
  const [logout] = usePostLogoutMutation();
  const [loginNoCredentials] = usePostLoginNoCredentialsMutation();
  useServerSideEvents();
  const dispatch = useAppDispatch();

  const sendLoginNoCredentials = async () => {
    try {
      const response = await loginNoCredentials(null).unwrap();
      window.location.href = response.redirect;
    } catch (error) {
      consoleErrorMessage(error);
    }
  };

  const sendLoginNoCredentialsNoRedirect = async () => {
    try {
      loginNoCredentials(null).unwrap();
    } catch (error) {
      consoleErrorMessage(error);
    }
  };

  const handleLogout = () => {
    dispatch(setAuthError(undefined));
    window.location.href = `${Config?.CSB_OAUTH_ONE_ID_DOMAIN}/idp/startSLO.ping?TargetResource=${window.location.origin}/dashboard&InErrorResource=${window.location.origin}/dashboard`;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    login(null)
      .unwrap()
      .catch((error) => {
        if (error.status === 401) {
          if (!code) {
            sendLoginNoCredentials();
          } else {
            sendLoginNoCredentialsNoRedirect();
          }
          dispatch(setAuthError(401));
        } else if (error.status === 500 && code) {
          queryParams.delete('code');
          login(null);
          dispatch(setAuthError(500));
        }
      });
    LicenseManager.setLicenseKey(Config?.REACT_APP_AG_GRID_LICENCE);
  }, [login, logout]);

  // Effect for auto logout
  useEffect(() => {
    const resetTimeout = (e: any) => {
      currentClearTimeout(logoutTimer);
      currentSetTimeout(
        logoutTimer,
        () => {
          logout(null);
        },
        DEFAULT_TIMEOUT
      );
    };

    for (const event of EVENTS_FOR_LOGOUT) {
      window.addEventListener(event, resetTimeout);
    }

    return () => {
      for (const event of EVENTS_FOR_LOGOUT) {
        window.removeEventListener(event, resetTimeout);
      }
    };
  }, []);

  return (
    <div>
      <HeaderCsb />
      {!isLoggedIn && showAuthErrorNotification && (
        <NotificationBox
          notificationContent={
            NOTIFICATION_BOX_CONTENT[NOTIFICATION_BOX_MESSAGES.AUTH_ERROR]
          }
          onCta={handleLogout}
        />
      )}

      {isLoggedIn && (
        <div className="App">
          <WkIconDefaultSprite />
          <BrowserRouter>
            <SpineMenuCsb>
              <Routes>
                <Route
                  path="/dashboard/orchestration/:id"
                  element={<OrchestrationDetailsLazy />}
                />
                <Route path="/dashboard" element={<DashboardLazy />} />
                <Route
                  path="/run-orchestrations"
                  element={<RunOrchestrationsLazy />}
                />
                <Route path="/manage-modules" element={<ManageModulesLazy />} />
              </Routes>
            </SpineMenuCsb>
          </BrowserRouter>
        </div>
      )}
    </div>
  );
}

export default App;
